<template>
  <div class="app-container">

    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">

      <el-form-item label-width="10" label="序号" prop="id">
        <el-input filterable v-model="queryParams._id" placeholder="请输入序号" clearable @clear="queryParams.id = undefined"
          @keyup.enter.native="handleQuery" />
      </el-form-item>

      <el-form-item label="疼痛部位" prop="pain_sites">
        <el-select filterable v-model="queryParams.pain_site_ids" clearable multiple
          @clear="queryParams.pain_sites = undefined" placeholder="请选择疼痛部位">
          <el-option v-for="item in dic['pain_site']" :key="item.value" :label="item.label"
            :value="parseInt(item.value)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="疼痛性质" prop="pain_types">
        <el-select filterable v-model="queryParams.pain_type_ids" clearable multiple
          @clear="queryParams.pain_types = undefined" placeholder="请选择疼痛部位">
          <el-option v-for="item in dic['pain_type']" :key="item.value" :label="item.label"
            :value="parseInt(item.value)"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="疼痛强度" prop="pain_intensity">
        <el-input type="number" :min="0" :max="10" filterable v-model.number="queryParams.pain_intensity" clearable
         @clear="queryParams.pain_intensity = undefined" placeholder="请输入疼痛强度" @keyup.enter.native="handleQuery" />
      </el-form-item>

      <el-form-item label-width="100" label="爆发痛类型" prop="eruptive_pain_type_id">
        <el-select filterable v-model="queryParams.eruptive_pain_type_id" clearable
          @clear="queryParams.eruptive_pain_type_id = undefined" placeholder="请选择爆发痛类型">
          <el-option v-for="item in dic['eruptive_pain_type']" :key="item.value" :label="item.label"
            :value="parseInt(item.value)" />
        </el-select>
      </el-form-item>

      <el-form-item label-width="100" label="爆发痛次数" prop="eruptive_pain_count_id">
        <el-select filterable v-model="queryParams.eruptive_pain_count_id" clearable
          @clear="queryParams.eruptive_pain_count_id = undefined" placeholder="请选择爆发痛次数">
          <el-option v-for="item in dic['eruptive_pain_count']" :key="item.value" :label="item.label"
            :value="parseInt(item.value)" />
        </el-select>
      </el-form-item>
      <!-- decision_id -->
      <el-form-item label-width="100" label="既往服用镇痛药物" prop="analgesics">
        <el-select filterable v-model="queryParams.analgesic_ids" multiple placeholder="请选择既往服用镇痛药物" clearable>
          <el-option v-for="item in dic['analgesic']" :key="item.value" :label="item.label"
            :value="parseInt(item.value)" />
        </el-select>
      </el-form-item>
      <el-form-item label-width="100" label="既往服用镇痛药物种类" prop="analgesic_type_id">
        <el-select filterable v-model="queryParams.analgesic_type_id" clearable
          @clear="queryParams.analgesic_type_id = undefined" placeholder="请选择种类">
          <el-option v-for="item in dic['analgesic_type']" :key="parseInt(item.value)" :label="item.label"
            :value="parseInt(item.value)" />
        </el-select>
      </el-form-item>
      <el-form-item label-width="100" label="决策" prop="decision_id">
        <el-select filterable v-model="queryParams.decision_id" clearable @clear="queryParams.decision_id = undefined"
          placeholder="请选择决策">
          <el-option v-for="item in dic['decision']" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label-width="100" label="既往服用药物大类" prop="big_drug_type">
        <el-select filterable clearable v-model="queryParams.big_drug_type_ids" multiple
          @clear="queryParams.big_drug_type = undefined" placeholder="请选择类别">
          <el-option v-for="item in dic['big_drug_type']" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="daterangeCreateTime" style="width: 240px" value-format="yyyy-MM-dd" type="daterange"
          range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-date-picker v-model="daterangeUpdateTime" style="width: 240px" value-format="yyyy-MM-dd" type="daterange"
          range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>

    </el-form>

    <el-row :gutter="10" class="mb8">
      <!-- <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
        >新增</el-button>
      </el-col> -->
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"
          @click="handleUpdate">修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"
          @click="handleDelete">删除</el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="caseList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" align="center" prop="id" />
      <el-table-column label="疼痛部位" align="center" prop="pain_sites" width="150">
        <template slot-scope="scope">
          <el-row v-for="i in scope.row.pain_sites" :key="i.value">
            <el-tag size="mini" type="warning" effect="plain">{{ i.label }}</el-tag>
          </el-row>
        </template>
      </el-table-column>


      <el-table-column label="疼痛性质" align="center" prop="pain_types">
        <template slot-scope="scope">
          <el-row v-for="i in scope.row.pain_types" :key="i.value">
            <el-tag size="mini" effect="plain">{{ i.label }}</el-tag>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column label="疼痛强度" align="center" prop="pain_intensity" />
      <el-table-column label="爆发痛类型" align="center" prop="eruptive_pain_type" width="90" />
      <el-table-column width="100" label="爆发痛次数" align="center" prop="eruptive_pain_count" />
      <el-table-column label="既往服用镇痛药物" align="center" prop="analgesics" width="220">
        <template slot-scope="scope">
          <el-row v-for="i in scope.row.analgesics" :key="i.value">

            <el-tooltip placement="right-end" effect="light">
              <div slot="content">
                <div v-for="k in drug_type_dic[i.value]" :key="k"> {{ k }}</div>
              </div>
              <el-tag size="mini" type="success" effect="plain">{{ i.label }}</el-tag>
            </el-tooltip>

          </el-row>
        </template>
      </el-table-column>

      <el-table-column label="既往服用镇痛药物种类" align="left" prop="analgesic_type" width="220" />

      <el-table-column label="日剂量" align="left" prop="daily_dose" width="100" />
      <el-table-column label="决策" align="center" prop="decision" width="200" />
      <el-table-column label="创建时间" align="center" prop="create_time" width="200">

      </el-table-column>

      <el-table-column label="修改时间" align="center" prop="update_time" width="200" />


      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">


        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="per_page"
      @pagination="getList" />

    <!-- 添加或修改文档对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="550px" append-to-body>

      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="疼痛部位" prop="pain_site_ids">
              <el-select style="width:100%" filterable v-model="form.pain_site_ids" multiple placeholder="请选择疼痛部位">
                <el-option v-for="dict in dic['pain_site']" :key="dict.value" :label="dict.label"
                  :value="parseInt(dict.value)"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="疼痛性质" prop="pain_type_ids">
            <el-select style="width:100%" filterable v-model="form.pain_type_ids" multiple placeholder="请选择疼痛部位">
              <el-option v-for="dict in dic['pain_type']" :key="dict.value" :label="dict.label"
                :value="parseInt(dict.value)"></el-option>
            </el-select>
          </el-form-item>

        </el-row>
        <el-row>

          <div class="block">
            <el-form-item label="疼痛强度" prop="pain_intensity">
              <el-slider style="width:100%" v-model="form.pain_intensity" :step="1" :max="10" show-input show-stops>
              </el-slider>
            </el-form-item>

          </div>
        </el-row>

        <el-row>
          <el-form-item label="爆发痛类型" prop="eruptive_pain_type_id">

            <el-radio-group style="width:100%" v-model="form.eruptive_pain_type_id">
              <el-radio v-for="dict in dic['eruptive_pain_type']" :key="dict.value" :label="dict.value">{{ dict.label
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>

          <el-form-item label="爆发痛次数" prop="eruptive_pain_count_id">
            <el-radio-group v-model="form.eruptive_pain_count_id" size="100">
              <el-radio v-for="dict in dic['eruptive_pain_count']" :key="dict.value" :label="dict.value">{{ dict.label
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>

        <el-row>

          <el-form-item label-width="180" label="既往服用镇痛药物" prop="analgesic_ids">
            <el-select style="width:73%" filterable v-model="form.analgesic_ids" multiple placeholder="请选择镇痛药物">
              <el-option v-for="dict in dic['analgesic']" :key="dict.value" :label="dict.label"
                :value="parseInt(dict.value)"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label-width="100" label="既往服用镇痛药物种类" prop="analgesic_type_id">
            <el-select style="width:68%" filterable v-model="form.analgesic_type_id" placeholder="请选择镇痛药物种类">
              <el-option v-for="dict in dic['analgesic_type']" :key="dict.value" :label="dict.label"
                :value="parseInt(dict.value)"></el-option>
            </el-select>
          </el-form-item>
        </el-row>

        <el-row>

          <el-form-item align="left" label="日剂量" prop="daily_dose">
            <el-input align="left" v-model="form.daily_dose" placeholder="请输入日剂量(使用逗号隔开)" />
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="决策" prop="decision_id">
            <el-select style="width:100%" filterable v-model="form.decision_id" placeholder="请选择决策">
              <el-option v-for="dict in dic['decision']" :key="dict.value" :label="dict.label"
                :value="dict.value"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>


<script>
import { listCase, getCase, updateCase, deleteCase } from "@/api/case";
import { getAllList, getAllDic } from "@/api/dic";


export default {
  created() {
    this.getDic();
    this.getList();
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 案例表格数据
      caseList: [],
      // 总条数
      total: 0,
      // 表单参数
      form: {},
      open: false,
      title: '',
      // 创建时间范围
      daterangeCreateTime: [],
      // 更新时间范围
      daterangeUpdateTime: [],
      queryParams: {
        "page": 1,
        "per_page": 10,
      },
      per_page: 10,
      originalForm: {},
      showSearch: true,
      dic: {
        "big_drug_type": [{
          "value": "A",
          "label": "非甾体类"
        },
        {
          "value": "B",
          "label": "抗惊厥类/抗抑郁类"
        },
        {
          "value": "C",
          "label": "缓释强阿片"
        },
        {
          "value": "D",
          "label": "缓释弱阿片"
        },
        {
          "value": "E",
          "label": "即释强阿片"
        },
        {
          "value": "F",
          "label": "即释弱阿片"
        }
        ],
      },

      // pain_type_dic: [],
      // eruptive_pain_type_dic: [],
      // eruptive_pain_count_dic: [],
      // decision_dic: [],
      // analgesic_dic: [],
      // analgesic_type_dic: [],


      drug_type_dic: {},
      queryForm: {
        id: null,
        pain_site_ids: null,
        pain_type_ids: null,
        pain_intensity: null,
        eruptive_pain_type_id: null,
        eruptive_pain_count_id: null,
        analgesic_ids: null,
        analgesic_type_id: null,
        decision_id: null,
        big_drug_type: null
      },
      rules: {
        pain_sites: [
          { required: true, message: "疼痛部位不能为空", trigger: "blur" },

        ],
        pain_types: [
          { required: true, message: "疼痛性质不能为空", trigger: "blur" },
        ],
        pain_intensity: [
          { required: true, message: "疼痛强度不能为空", trigger: "blur" },
          // { min: 0, max: 10, message: '标题长度必须介于 2 和 100 之间', trigger: 'blur' }
        ],
        eruptive_pain_type_id: [
          { required: true, message: "爆发痛类型不能为空", trigger: "blur" }
        ],
        eruptive_pain_count_id: [
          { required: true, message: "爆发痛次数不能为空", trigger: "blur" }
        ],
        analgesics: [
          { required: true, message: "既往服用镇痛药物不能为空", trigger: "blur" },
        ],
        analgesic_type_id: [
          { required: true, message: "既往服用镇痛药物种类不能为空", trigger: "blur" }
        ],
        daily_dose: [
          { required: true, message: "日剂量不能为空", trigger: "blur" }
        ],
        decision_id: [
          { required: true, message: "决策不能为空", trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getCase(id).then(response => {
        this.originalForm = response.data.result;
        this.form = _.cloneDeep(this.originalForm);
        this.open = true;
        this.title = "修改文档";
      });
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        pain_site_ids: null,
        pain_type_ids: null,
        pain_intensity: null,
        eruptive_pain_type_id: null,
        eruptive_pain_count_id: null,
        analgesics: null,
        analgesic_type_id: null,
        daily_dose: null,
        decision_id: null,
      };
      // this.resetForm("form");
    },
    isEmptyObject(obj) {
      for (var key in obj) {
        return false;//返回false，不为空对象
      }
      return true;//返回true，为空对象
    },
    getList() {
      this.queryParams.per_page = this.per_page
      this.loading = true;
      this.queryParams.params = undefined
      if (null != this.daterangeCreateTime && '' != this.daterangeCreateTime) {
        this.queryParams["begin_create_time"] = this.daterangeCreateTime[0];
        this.queryParams["end_create_time"] = this.daterangeCreateTime[1];
      }
      if (null != this.daterangeUpdateTime && '' != this.daterangeUpdateTime) {
        this.queryParams["begin_update_time"] = this.daterangeUpdateTime[0];
        this.queryParams["end_update_time"] = this.daterangeUpdateTime[1];
      }

      listCase(this.queryParams).then(response => {
        this.caseList = response.data.result.cases
        this.total = response.data.result.total
        this.loading = false;
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    handleInput(value) {
    // 将输入值转换为整数，如果无法转换则置为空字符串
      this.queryParams.pain_intensity = Number.isInteger(Number(value)) ? String(value) : '';
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = [];
      this.daterangeUpdateTime = [];
      this.queryForm = []
      this.queryParams = {
        "page": 1,
        "per_page": 10,
      },
        this.handleQuery();
    },
    getDic() {
      const collections = ["pain_site", "pain_type", "eruptive_pain_type", "eruptive_pain_count", "decision", "analgesic", "analgesic_type"]
      for (let i = 0; i < collections.length; i++) {
        getAllList(collections[i]).then(response => {
          this.$set(this.dic, collections[i], response.data.result);
        })
      }
      getAllDic("drug_type").then(response => {
        this.drug_type_dic = response.data.result
      })
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    getNewForm() {
      var flag = false
      var newForm = { "id": this.form.id }
      for (var i in this.form) {
        if (!_.isEqual(this.form[i], this.originalForm[i])) {
          newForm[i] = this.form[i]
          flag = true
        }
      }
      return flag === false ? flag : newForm
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            var newForm = this.getNewForm()
            if (!newForm) {
              this.$message({
                type: 'info',
                message: "没有做出任何修改！"
              });
              this.open = false;
            } else {
              updateCase(newForm).then(response => {
                if (response.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '修改成功!'

                  });
                  this.getList();
                }
                this.open = false;

              });
            }
          }
          //   else {
          //     addDocument(this.form).then(response => {
          //       this.$modal.msgSuccess("新增成功");
          //       this.open = false;
          //       this.getList();
          //     });
          //   }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除序号为"' + ids + '"的案例？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return deleteCase(ids);
      }).then(() => {
        this.getList();
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}




nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
