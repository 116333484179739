import axios from 'axios';


const request = axios.create({
  // baseURL: 'https://flask-mongo-git-main-zack998.vercel.app/'
  // baseURL: 'http://localhost:5000/'
  // baseURL: 'https://flask-mongo.vercel.app/'
  baseURL: 'https://vercel2.keceng.cn/'
  
}) 

export default request
