import request from "@/utils/request"


// 查询案例列表
export function listCase(data) {
  return request({
    url: '/case/list',
    method: 'post',
    data: data
  })
}

// 查询单个案例
export function getCase(id) {
  return request({
    url: '/case/query?id=' + id,
    method: 'get',
  })
}
// 修改单个案例
export function updateCase(data) {
  return request({
    url: '/case/update',
    method: 'put',
    data: data
  })
}
// 删除单个案例
export function deleteCase(id) {
  return request({
    url: '/case/delete/' + id,
    method: 'delete'
  })
}