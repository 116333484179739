import request from "@/utils/request"


// 查询列表
export function getAllList(collection) {
  return request({
    url: '/dic/getList/' + collection,
    method: 'get',
  })
}
// 查询字典
export function getAllDic(collection) {
  return request({
    url: '/dic/getDic/' + collection,
    method: 'get',
  })
}